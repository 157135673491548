import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import CardTransformationStory from '../components/CardTransformationStory'
import Photo from '../components/Photo'

class TransformationsOverviewRoute extends React.Component {
    render() {
        const transformationsOverviewData = this.props.data
        const title = transformationsOverviewData.kontentItemSiteMetadata.elements.title.value
        const kontentItemTransformationsOverviewPage = transformationsOverviewData.kontentItemTransformationsOverviewPage
        var subtitle = kontentItemTransformationsOverviewPage.elements.title.value
        var description = kontentItemTransformationsOverviewPage.elements.meta_description.value
        var storiesTitle = kontentItemTransformationsOverviewPage.elements.stories_title.value
        var otherTransformationsTitle = kontentItemTransformationsOverviewPage.elements.other_transformations_title.value
        const urlSlug = kontentItemTransformationsOverviewPage.elements.slug.value

        const stories = []
        const transformationStories = kontentItemTransformationsOverviewPage.elements.stories.value;
        transformationStories.map(item => (
            stories.push(<CardTransformationStory data={item} slug={urlSlug} key={item.id} />)
        ))

        const photoList = []
        const photos = kontentItemTransformationsOverviewPage.elements.other_transformations.value
        photos.forEach(photo => {
            photoList.push(<Photo data={photo} key={photo.url} />)
        })

        return (
            <Layout>
                <div>
                    <Meta title={subtitle} postfix={title} description={description} />
                    <Header />
                    <PageHeading data={subtitle} />
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2>{storiesTitle}</h2>
                                    <div className="card-list row">
                                        {stories}
                                    </div>
                                    <h2>{otherTransformationsTitle}</h2>
                                    <div className="photos-wrapper">
                                        {photoList}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </Layout>
        )
    }
}

export default TransformationsOverviewRoute

export const pageQuery = graphql`
  query TransformationsOverviewQuery($codename: String!) {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }    
    kontentItemTransformationsOverviewPage(system: {codename: {eq: $codename}}) {
      id
      system {
        id
      }
      elements {
        slug {
          value
        }
        description {
          value
        }
        meta_description  {
          value
        }
        title {
          value
        }
        stories_title {
          value
        }
        stories {
          value {
            ... on kontent_item_transformation {
              id
              elements {
                slug {
                  value
                }
                title {
                  value
                }
                teaser_image {
                  value {
                    name
                    fluid(maxWidth: 350) {
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                  }
                }
              }
            }
          }
        }
        other_transformations_title {
          value
        }
        other_transformations {
          value {
            fluid(maxWidth: 350) {
                aspectRatio
                ...KontentAssetFluid
            }
            fixed(height: 700) {
              aspectRatio
              src
              ...KontentAssetFixed
            }
            description
            url
          }
        }
      }
    }
  }
`

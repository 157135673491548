import React from 'react'
import GatsbyImage from 'gatsby-image'
import { Link } from 'gatsby'
import { format } from 'react-string-format'

class CardTransformationStory extends React.Component {
    render() {
        const transformationStoryData = this.props.data
        const transformationStoryElements = transformationStoryData.elements
        const title = transformationStoryElements.title.value
        const partnerSlug = this.props.slug
        const slug = `/` + partnerSlug + `/${transformationStoryElements.slug.value}`
        const teaserImage = transformationStoryData.elements.teaser_image

        return (
            <div className="col-lg-4">
                <div className="card">
                    <Link to={slug} data-tracking-label={format('TransitionStory_{0}', title)}>
                        <div className="card-thumb">
                            {teaserImage.value[0] ? (
                                <GatsbyImage fluid={teaserImage.value[0].fluid} />
                            ) : ""}
                        </div>
                        <div className="card-content">
                            <h3>{title}</h3>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}

export default CardTransformationStory
